<template>
	<el-dialog :title="'上传文件'" :close-on-click-modal="false" :visible.sync="subVisible" width="700px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px">
			<el-row>
				<el-col :span="24">
					<el-form-item label="月报编号" prop="monthlyName">
						{{ dataForm.monthlyName }}
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="6" style="margin-left: 20px;">
					<el-upload ref="uploadScanFile" :action="action" :http-request="uploadScanFile" :data="{ type: 1 }"
						aria-disabled="!dataForm.fileName">
						<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-upload">月报上传</el-button>
					</el-upload>
				</el-col>
				<el-col :span="17" v-if="dataForm.fileName">
					<div class="addFileBox">
						<span class="tmp" id="tmp" title="">{{ dataForm.fileName }}</span>
						<el-button v-preventReClick type="primary" size="small" @click="deleteReportFile()">删除</el-button>
						<el-button v-preventReClick type="success" size="small" @click="viewReportFile(dataForm.filePathUri, dataForm.fileName)"
							style="margin-left: 20px;">查看</el-button>
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24" style="margin-left: 20px;">
					<el-upload ref="uploadScanEncolsureFile" :action="action" :http-request="uploadScanFile"
						:data="{ type: 2 }">
						<el-button v-preventReClick class="defaultPlainBtn" plain
							icon="el-icon-upload">月报附件上传（最多能上传10个附件）</el-button>
					</el-upload>
				</el-col>
			</el-row>
			<el-row v-for="(enclosureFile, index) in dataForm.enclosureFiles" style="margin-top: 10px;">
				<el-col :span="24" v-if="dataForm.enclosureFiles">
					<span style="margin-left: 20px;margin-right: 20px;">{{ index + 1 }}</span>
					<el-input v-model="enclosureFile.fileName" placeholder="请填写名称"
						style="width: 300px;margin-left: 10px;"></el-input>
					<el-button v-preventReClick type="primary" size="small" @click="deleteEncolsureFile(index)"
						style="margin-left: 70px;">删除</el-button>
					<el-button v-preventReClick type="success" size="small" @click="viewReportFile(enclosureFile.filePathUri, enclosureFile.fileName)"
						style="margin-left: 20px;">查看</el-button>
				</el-col>
			</el-row>
		</el-form>
		<el-divider></el-divider>
		<el-button v-preventReClick type="primary" @click="dataFormSubmit" style="margin-top: 0px;margin-left: 280px;">保
			存</el-button>
	</el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "monthly-file-upload",
	data() {
		return {
			addImg: addImg,
			id: "",
			subVisible: true,
			form: {},
			action: "",
			fileType: "",
			dataForm: {
				recNo: '',
				monthlyName: '',
				fileName: "",
				filePath: "",
				enclosureFiles: [],
			},
			dataRule: {
				monthlyName: [
					{ required: true, message: "月报名称不能为空！", trigger: "blur" }
				],
			}
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		init(id) {
			this.dataForm = {
				recNo: '',
				monthlyName: '',
				fileName: "",
				filePath: "",
				filePathUri: '',
				enclosureFiles: []
			}
			this.dataForm.recNo = id
			this.getDetail();
			this.$nextTick(() => {
				this.subVisible = true;
			})
			this.$refs['dataForm'].resetFields();
		},
		deleteReportFile() {
			this.dataForm.filePath = '';
			this.dataForm.fileName = '';
		},
		viewReportFile(url, filename) {
            // filename = this.getSuffix(url, filename)
            this.getBlob(url, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },
		deleteEncolsureFile(index) {
			this.dataForm.enclosureFiles.splice(index, 1);
		},
		viewEncolsureFile(index) {
			window.open(this.dataForm.enclosureFiles[index].filePathUri);
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/monthlyreportinfo/info/" + this.dataForm.recNo,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm.recNo = data.body.recNo;
					this.dataForm.monthlyName = data.body.monthlyName;
					this.dataForm.filePath = data.body.filePath;
					this.dataForm.fileName = data.body.fileName;
					this.dataForm.filePathUri = data.body.filePathUri;
					this.dataForm.enclosureFiles = data.body.enclosureFiles;
				}
			});
		},
		uploadScanFile(param) {
			let type = param.data.type;
			const formData = new FormData();
			formData.append('file', param.file);
			if (type === 1) {
				this.$refs.uploadScanFile.clearFiles();
			} else {
				this.$refs.uploadScanEncolsureFile.clearFiles();
			}
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					if (type === 1) {
						this.dataForm.fileName = res.body.fileName;
						this.dataForm.filePath = res.body.fileSavePath;
						this.dataForm.filePathUri = res.body.fileUri
					} else {
						if (this.dataForm.enclosureFiles.length >= 10) {
							this.$message.error('最多只能上传10个附件！');
							return;
						}
						// 判断文件名称是否存在
						let exist = this.checkFileNameExist(res.body.fileName);
						if (exist) {
							this.$message.error("文件名称已存在");
							return;
						}
						let enclosureFile = {
							fileName: res.body.fileName,
							filePath: res.body.fileSavePath,
							filePathUri: res.body.fileUri
						}
						this.dataForm.enclosureFiles.push(enclosureFile);
					}
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		checkFileNameExist(fileName) {
			let enclosureFiles = this.dataForm.enclosureFiles;
			if (enclosureFiles) {
				for (var i = 0; i < enclosureFiles.length; i++) {
					if (enclosureFiles[i].fileName === fileName) {
						return true;
					}
				}
			}
			return false;
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					if (!this.dataForm.filePath || !this.dataForm.fileName) {
						this.$message.error("未上传月报正文");
						return;
					}
					let _url = "/business/monthlyreportinfo/updateReportFiles";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData({
							recNo: this.dataForm.recNo,
							workNo: $common.getItem("workNo"),
							monthlyName: this.dataForm.monthlyName,
							filePath: this.dataForm.filePath,
							fileName: this.dataForm.fileName,
							enclosureFiles: this.dataForm.enclosureFiles,
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.subVisible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 1030px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	position: relative;
}

.el-divider {
	margin: 60px 0;
	background: 0 0;
	border-top: 1px solid #E6EBF5;
}

.tmp {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 248px;
	display: inline-block;
	color: #00428e;
	margin-top: 13px;
}
</style>
