<template>
	<el-dialog title="月报导出" :close-on-click-modal="false" :visible.sync="visible"
		width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-form-item label="时间" prop="plannedTime">
				<el-date-picker  class="selItemInput"
					  v-model="timeArray"
					  type="month"
								 value-format="yyyy-MM">
				</el-date-picker>
			</el-form-item>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "monthly-add-or-update",
		data() {
			return {
				visible: false,
				inline: false,
				timeArray:[],
				dataForm: {

				},
				dataRule: {},
			}
		},
		methods: {
			init() {
                this.visible = true;

			},
            // 表单提交
            dataFormSubmit() {
                // this.$router.push({name:'weeklyExportInfo',params:{beginTime:this.timeArray[0],endTime:this.timeArray[1]}});
            },
		}
	}
</script>
<style scoped>

</style>
