<template>
	<el-dialog :title="!dataForm.recNo ? '附件新增' : '附件修改'" :close-on-click-modal="false" :visible.sync="subVisible"
		width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="文件名称" prop="fileName" class="item">
						<el-input class="sp-input" v-model="dataForm.fileName" placeholder="请选择"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="备注" class="item">
						<el-input class="sp-input" v-model="dataForm.remark" placeholder="请选择"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="文件" class="item">
						<div class="addFileBox">
							<div v-if="dataForm.filePathUri">
								<el-button v-preventReClick type="success" size="small"
									@click="selectFile(dataForm.filePathUri, dataForm.fileName)">查看</el-button>
								<el-button v-preventReClick type="primary" size="small" @click="delFile()">删除</el-button>
							</div>
						</div>
						<el-upload v-if="!dataForm.filePathUri" ref="uploadScanFile" class="upload-demo" action="action"
							drag :http-request="uploadScanFile" :show-file-list=false :auto-upload="true">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">确定</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="subVisible = false">取消</el-button>

			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "enclosure-add-or-update",
	data() {
		return {
			addImg: addImg,
			id: "",
			subVisible: true,
			form: {},
			action: "",
			fileType: "",
			dataForm: {
				detailRecNo: '',
				recNo: '',
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				fileName: "",
				filePath: "",
				filePathUri: '',
				remark: ""
			},
			dataRule: {
				fileName: [
					{ required: true, message: "请选择填写文件名称", trigger: "change" }
				],
			},
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		init(id, detailRecNo) {
			this.dataForm = {
				detailRecNo: '',
				recNo: '',
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				fileName: "",
				filePath: "",
				filePathUri: '',
				remark: ""
			}
			this.dataForm.recNo = id ? id : 0
			this.dataForm.detailRecNo = detailRecNo
			if (id) {
				this.getDetail();
			}
			this.$nextTick(() => {
				this.subVisible = true;
			})
			this.$refs['dataForm'].resetFields();

		},
		selectFile(url, filename) {
			filename = this.getSuffix(url, filename)
			this.getBlob(url, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		getSuffix(url, filename) {
			let lastIndex = url.lastIndexOf(".");
			return filename + url.substr(lastIndex)
		},
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.responseType = 'blob'
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response)
				}
			}
			xhr.send()
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename)
			}
			else {
				var link = document.createElement('a')
				var body = document.querySelector('body')

				link.href = window.URL.createObjectURL(blob)
				link.download = filename

				link.style.display = 'none'
				body.appendChild(link)

				link.click()
				body.removeChild(link)
				window.URL.revokeObjectURL(link.href)
			}
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/weekreportenclosure/info/" + this.dataForm.recNo,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body;
				}
			});
		},
		delFile() {
			this.dataForm.filePath = "";
			this.dataForm.loadFileName = "";
			this.dataForm.filePathUri = "";
		},
		uploadScanFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadScanFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					this.dataForm.filePath = res.body.fileSavePath;
					this.dataForm.loadFileName = res.body.fileName;
					this.dataForm.filePathUri = res.body.fileUri;
					this.dataForm.fileName = res.body.fileName.substring(0, res.body.fileName.indexOf('.')); //文件名称去掉路径和后缀名
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/weekreportenclosure/save";
					if (this.dataForm.recNo && this.dataForm.recNo !== 0) {
						_url = "/business/weekreportenclosure/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.subVisible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 1030px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	position: relative;
}

.item::v-deep .el-form-item__label {
    color: #00428E !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
    border-bottom: solid #dadada 1px;
    border-left: solid #dadada 1px;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
    border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
    display: flex !important;
    align-items: stretch !important;
    margin-bottom: 0px !important;
}

.sp-input::v-deep .el-input__inner {
    width: 360px !important;
}

.upload-demo::v-deep .el-upload-dragger {
	margin-top: 5px;
	margin-bottom: -8px;
	width: 200px;
	height: 80px;
}

.upload-demo::v-deep .el-upload-dragger .el-icon-upload {
	font-size: 30px;
	margin: 8px 1px;
	line-height: 20px;
}

.upload-demo::v-deep .el-upload-dragger .el-upload__text {
	line-height: 5px;
}
</style>
