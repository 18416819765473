<template>
	<!-- 监理月报 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">监理月报</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan">
					<!-- <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-refresh" @click="resetFormData()"
					v-if="showSearch">刷新</el-button> -->
					<!--			  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-download" @click="exportHandle()" v-if="showDownload">月报导出</el-button>-->
					<el-button v-preventReClick class="btn" type="primary" size="small" @click="addOrUpdateHandle()"
						v-if="showAdd">创建</el-button>
					<!-- <el-button v-preventReClick class="btn" type="primary" size="small"
						@click="monthlyReportUpload()">上传</el-button> -->
				</el-col>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -3px; height: 590px;" body-style="height:550px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="530" :row-class-name="tableRowClassName" class="dataListTable" border
								:data="dataList" header-align="center" style="width: 100%;margin-bottom: 20px;"
								header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="monthlyName" label="月报名称" min-width="180" align="center">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="viewPdf(scope.row.filePath, scope.row.filePathUri, scope.row.weeklyName)">{{
												scope.row.monthlyName }}</el-button>
									</template>
								</el-table-column>
								<el-table-column prop="monthlyTerm" label="期数" min-width="100" align="center">
								</el-table-column>
								<el-table-column label="日期" min-width="190" align="center">
									<template slot-scope="scope">
										{{ scope.row.beginTime }} ~~ {{ scope.row.endTime }}
									</template>
								</el-table-column>
								<!-- <el-table-column prop="beginTime" label="开始时间" align="center" min-width="160">
								</el-table-column>
								<el-table-column prop="endTime" label="结束时间" align="center" min-width="160">
								</el-table-column> -->
								<el-table-column prop="insDate" label="制作日期" width="100" align="center">
								</el-table-column>
								<!-- <el-table-column prop="monthlyKindName" label="形式 " min-width="160" align="center">
								</el-table-column> -->
								<el-table-column label="确认状态" width="90" align="center">
									<template slot-scope="scope">
										<span v-if="scope.row.refirmFlg === '1'">已确认</span>
										<span v-else>未确认</span>
									</template>
								</el-table-column>
								<el-table-column prop="refirmMan" label="确认人" width="110" align="center">
								</el-table-column>
								<el-table-column prop="refirmTime" label="确认时间" width="110" align="center">
								</el-table-column>
								<el-table-column label="操作" min-width="220" fixed="right"
									v-if="showDelete || showEdit || showRowDownload" align="left">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="editHandle(scope.row.recNo, scope.row.monthlyStatus)"
											v-if="showEdit && scope.row.refirmFlg != '1'">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="createHandle(scope.row.recNo)"
											v-if="scope.row.monthlyStatus === '1' && scope.row.refirmFlg !== '1'">生成</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="fileUploadHandle(scope.row.recNo)"
											v-if="scope.row.refirmFlg !== '1'">上传</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="refirmHandle(scope.row.recNo)"
											v-if="scope.row.showRefirm === '1'">确认</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="cancelRefirmHandle(scope.row.recNo)"
											v-if="scope.row.refirmFlg === '1'">解除确认</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="downloadHandle(scope.row.recNo)"
											v-if="showRowDownload">下载</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="zipDownLoadWeekly(scope.row)"
											v-if="showRowDownload">打包下载</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="deleteHandle(scope.row.recNo)"
											v-if="showDelete && scope.row.refirmFlg != '1'">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
		<monthly-report-upload v-if="reportUploadVisible" ref="weeklyReportUpload"
			@refreshDataList="getDataList"></monthly-report-upload>
		<monthly-export v-if="exportVisible" ref="monthlyExport" @refreshDataList="getDataList"></monthly-export>
		<audit v-if="auditVisible" ref="audit" @refreshDataList="getDataList"></audit>
		<enclosure-add-or-update v-if="enclosureVisible" ref="enclosureAddOrUpdate"
			@refreshDataList="getDataList"></enclosure-add-or-update>
		<monthly-report-file-upload v-if="reportFileUploadVisible" ref="mothlyReportFileUpload"
			@refreshDataList="getDataList"></monthly-report-file-upload>
		<monthly-report-download v-if="downloadVisible" ref="monthlyReportDownload"
			@refreshDataList="getDataList"></monthly-report-download>
		<monthly-refirm v-if="refirmVisible" ref="monthlyRefirm" @refreshDataList="getDataList"></monthly-refirm>
		<view-pdf v-if="viewPdfVisible" ref="viewPdf" @refreshDataList="dataList"></view-pdf>
	</div>
</template>
<script>
import AddOrUpdate from './monthly-add-or-update'
import $common from "@/utils/common.js"
import MonthlyExport from './monthly-export'
import EnclosureAddOrUpdate from './enclosure-list'
import MonthlyReportUpload from "./monthly-report-upload.vue"
import MonthlyReportFileUpload from "./monthly-report-file-upload.vue"
import MonthlyReportDownload from "./monthly-report-download.vue"
import audit from './monthly-audit'
import WeeklyReportUpload from "@/views/modules/projectInfoManage/weekly-report-upload.vue";
import MonthlyRefirm from "./monthly_refirm.vue"
import ViewPdf from "../projectInfo/viewPdf.vue"
export default {
	data() {
		return {
			viewPdfVisible: false,
			refirmVisible: false,
			showSearch: false,
			showDownload: false,
			showAdd: false,
			showEdit: false,
			showDelete: false,
			showCheck: false,
			showArchive: false,
			showCreateFile: false,
			showRowDownload: false,
			pageSpan: 12,
			tabIndex: "0",
			addOrUpdateVisible: false,
			fileUploadVisible: false,
			exportVisible: false,
			auditVisible: false,
			enclosureVisible: false,
			reportUploadVisible: false,
			reportFileUploadVisible: false,
			downloadVisible: false,
			form: {},
			checked: "",
			dataList: [],
			tableSelVal: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
		};
	},
	components: {
		AddOrUpdate,
		audit,
		MonthlyExport,
		EnclosureAddOrUpdate,
		MonthlyReportUpload,
		MonthlyReportFileUpload,
		MonthlyReportDownload,
		MonthlyRefirm,
		ViewPdf
	},
	mounted() {
		this.showSearch = $common.isAuth('monthly:search');
		this.showDownload = $common.isAuth('monthly:download');
		this.showAdd = $common.isAuth('monthly:add');
		this.showEdit = $common.isAuth('monthly:edit');
		this.showDelete = $common.isAuth('monthly:delete');
		this.showCheck = $common.isAuth('monthly:check');
		this.showArchive = $common.isAuth('monthly:archive');
		this.showCreateFile = $common.isAuth('monthly:createFile');
		this.showRowDownload = $common.isAuth('monthly:download');
		if (!this.showSearch && !this.showDownload && !this.showAdd) {
			this.pageSpan = 24;
		}
		this.getDataList();
	},
	methods: {
		viewPdf(filePath, fileUri, filename) {
			if(filePath == null || filePath == undefined || filePath == '') {
				this.$message.error("没有可预览的文件！");
				return;
			} 
			// pdf和doc文件，需要pdf在线预览，其他类型文件直接下载
			let suffixType = fileUri.substr(fileUri.lastIndexOf(".") + 1)
			if(suffixType != '' && (suffixType === 'pdf' || suffixType === 'doc' || suffixType === 'docx')) {
				// $common.viewFile(filePath)
				this.viewPdfVisible = true;
				this.$nextTick(() => {
					this.$refs.viewPdf.init(filename, filePath)
				})
			} else {
				filename = this.getSuffix(fileUri, filename)
				this.getBlob(fileUri, (blob) => {
					this.saveAs(blob, filename)
				})
			}
		},
		//重置
		resetFormData() {
			this.pageIndex = 1;
			this.getDataList();
		},
		cancelAuditeHandle(id) {
			this.$confirm(`确定对选中的数据进行撤销审核生成月报吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/monthlyreportinfo/cancelCheck?recNo=" + id,
					method: "POST",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
		},
		cancelHandle(id) {
			this.$confirm(`确定对选中的数据进行还原生成月报吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/monthlyreportinfo/restore?recNo=" + id,
					method: "POST",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
		},
		createHandle(id) {
			this.$confirm(`确定对选中的数据进行生成月报吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/monthlyreportinfo/downloadMonthly?recNo=" + id,
					method: "POST",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
		},
		archiveFile(id) {
			this.$confirm(`确定对选中的发文进行归档操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/monthlyreportinfo/archiveFile/" + id,
					method: "get",
					data: id,
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error("归档设置失败")
					}
				});
			});
		},
		removeArchiveFile(id) {
			this.$confirm(`确定对选中的发文进行取消归档操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/monthlyreportinfo/removeArchiveFile/" + id,
					method: "get",
					data: id,
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error("取消归档失败")
					}
				});
			});
		},
		editHandle(id, monthlyStatus) {
			// 周报状态为初始化，不进行数据刷新
			if (monthlyStatus === "0") {
				this.refreshEnclosureFiles(id)
			} else {
				this.refreshAllDataList(id)
			}
		},
		refreshEnclosureFiles(id) {
			let _url = "/business/monthlyreportinfo/refreshEnclosureFiles/" + id;
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: []
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// 跳转
					this.$router.push({ name: 'monthlyExportInfo', query: { recNo: id } });
				}
			});
		},
		refreshAllDataList(id) {
			let _url = "/business/monthlyreportinfo/refreshAllDataList/" + id;
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: []
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// 跳转
					this.$router.push({ name: 'monthlyExportInfo', query: { recNo: id } });
				}
			});
		},
		//删除
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/monthlyreportinfo/delete/" + id,
					method: "get",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
		},
		// 获取数据列表
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/monthlyreportinfo/list",
				method: 'get',
				params: this.$http.adornParams({
					page: this.pageIndex,
					limit: this.pageSize,
					subjectNo: $common.getItem("subjectNo"),
					workNo: $common.getItem("workNo")
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalPage = data.body.totalCount;
					this.pageIndex2 = this.pageIndex
				} else {
					this.dataList = []
					this.totalPage = 0
					this.pageIndex2 = 1
				}
			})
		},
		// 审核
		auditeHandle(id) {
			this.auditVisible = true
			this.$nextTick(() => {
				this.$refs.audit.init(id)
			})
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		// 当前页
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 160;
			return { height: height + 'px' }
		},
		handleSelectionChange(val) {
			this.tableSelVal = val;
		},
		tableRowClassName({ row, rowIndex }) {
			let color = ''
			for (let item of this.tableSelVal) {
				if (item.id === row.id) color = 'table-SelectedRow-bgcolor'
			}
			return color
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		monthlyReportUpload() {
			this.reportUploadVisible = true;
			this.$nextTick(() => {
				this.$refs.weeklyReportUpload.init()
			})
		},
		fileUploadHandle(id) {
			this.reportFileUploadVisible = true
			this.$nextTick(() => {
				this.$refs.mothlyReportFileUpload.init(id)
			})
		},
		refirmHandle(id) {
			this.refirmVisible = true
			this.$nextTick(() => {
				this.$refs.monthlyRefirm.init(id)
			})
		},
		cancelRefirmHandle(id) {
			this.$confirm(`确定对选中的数据进行解除确认操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/monthlyreportinfo/cancelMonthlyRefirm/" + id,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
		},
		downloadHandle(id) {
			this.downloadVisible = true;
			this.$nextTick(() => {
				this.$refs.monthlyReportDownload.init(id)
			})
		},
		// 打包下载
		zipDownLoadWeekly(item) {
			var fileName = "监理月报（" + item.monthlyName + "）.zip";
			this.$http({
				url: this.$store.state.httpUrl + "/business/monthlyreportinfo/zipDownLoadMonthly?recNo=" + item.recNo,
				method: "POST",
				data: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							this.global.downLoadFile(data.body, fileName);
						}
					})
				}
			});
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.my-pagination {
	margin-right: 20px !important;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}
</style>
