
<template>
	<div>
	<el-dialog title="附件信息" :close-on-click-modal="false" :visible.sync="visible"
		width="900px">
		<el-row>
			<el-col :span="12">
				<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-upload" @click="editHandle()">添加附件</el-button>
			</el-col>
			<el-col :span="12" style="text-align: right;">
				<el-pagination
						background
						@size-change="sizeChangeHandle"
						@current-change="currentChangeHandle"
						layout="prev, pager, next"
						:pager-count="5"
						:current-page="page"
						:page-size="limit"
						:total="totalCount">
				</el-pagination>
			</el-col>
		</el-row>
		<el-row>
			<el-table
					class="dataListTable"
					:data="dataList"
					header-align="center"
					height="100%"
					style="width: 100%;"
					header-cell-class-name="dataListTHeader"
			>
				<el-table-column
						type="index"
						width="60"
						align="center">
				</el-table-column>
				<el-table-column
						prop="fileName"
						label="文件名称"
						align="center">
				</el-table-column>
				<el-table-column
						label="备注"
						prop="remark"
						align="center" width="300" show-overflow-tooltip>
				</el-table-column>
				<el-table-column
						label="操作"
						prop="remark"
						align="center" width="300">
					<template  slot-scope="scope">
						<el-button v-preventReClick type="danger" size="small" @click="downloadHandle(scope.row.filePathUri)" v-if="scope.row.filePathUri">下载</el-button>
						<el-button v-preventReClick type="warning" size="small" @click="editHandle(scope.row.recNo)">修改</el-button>
						<el-button v-preventReClick type="danger" size="small" @click="deleteHandle(scope.row.recNo)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-row>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="visible = false">取消</el-button>
		</el-row>


	</el-dialog>
	<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
	</div>
</template>

<script>
	import $common from "@/utils/common.js"
	import AddOrUpdate from './enclosure-add-or-update'
	export default {
		name: "enclosure-list",
		data() {
			return {
				visible: false,
				inline: false,
				addOrUpdateVisible: false,
				action: '',
				recNo: '',
				dataList: [],
				limit: 10,
				page: 1,
				totalCount:0,
			}
		},
		components: {
			AddOrUpdate
		},
		methods: {
			downloadHandle(url){
				window.open(url);
			},
			editHandle(id){
				this.addOrUpdateVisible = true
				this.$nextTick(() => {
					this.$refs.addOrUpdate.init(id,this.recNo)
				})
			},
			deleteHandle(id){
				this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url:  this.$store.state.httpUrl + "/business/weekreportenclosure/delete",
						method: "POST",
						data: this.$http.adornData([id], false),
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.getDataList()
								}
							})
						} else {
							this.$message.error(data.msg)
						}
					});
				})
			},
			init(id) {
                this.visible = true;
				this.recNo = id?id:'';
				this.$nextTick(() => {
                    if (this.recNo) {
                        this.getDataList()
                    } else {
						this.dataList = []
						this.totalCount = 0
					}
				});
			},
			getDataList(){
				this.$http({
					url:  this.$store.state.httpUrl +`/business/weekreportenclosure/list`,
					method: 'get',
					params: {
						workNo: $common.getItem("workNo"),
						detailRecNo: this.recNo
					}
				}).then(({data}) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records
						this.totalCount = data.body.totalCount
					} else {
						this.dataList = []
						this.totalCount = 0
					}
				})
			},
			// 每页数
			sizeChangeHandle(val) {
				this.limit = val
				this.page = 1
				this.getDataList()
			},
			// 当前页
			currentChangeHandle(val) {
				this.page = val
				this.getDataList()
			},
		}
	}
</script>
<style scoped>
	.selItemInput1 .el-input__inner{
		border-color: #c7d0e1 !important;
		color: #00428e;
		width: 470px;
		box-sizing: border-box;
	}
</style>
