import { render, staticRenderFns } from "./monthly-audit.vue?vue&type=template&id=47fd6cb4&scoped=true&"
import script from "./monthly-audit.vue?vue&type=script&lang=js&"
export * from "./monthly-audit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47fd6cb4",
  null
  
)

export default component.exports